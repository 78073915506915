@use "../config/" as *;
.pagination {
    height: 4.4rem;
    @extend %px-2;
    @extend %half-radius;
    @extend %primary-50-bg;
    @include flex-config(inline-flex, null, center, center);
    &-wrap {
        flex-wrap: wrap;
        overflow: auto;
        @extend %my-8;
        @extend %flex-c-n;
    }
    &-number {
        @extend %flex;
        a {
            @extend %neutral-800;
            @extend %font-14-pb;
            &.active {
                @extend %primary-300-bg;
            }
        }
    }
    &-result {
        @extend %text-center;
        @extend %w-100;
        @extend %pt-4;
        &-text {
            @extend %font-12-pm;
            @extend %neutral-600;
        }
    }
    a {
        min-width: 3rem;
        width: max-content;
        height: 3rem;
        padding-inline: .2rem;
        cursor: pointer;
        @extend %flex-c-c;
    }
    .nav {
        &-first,
        &-last,
        &-left,
        &-right {
            @extend %font-0;
            @extend %neutral-800;
            &.disabled {
                pointer-events: none;
                opacity: 0.5
            }
            &:before {
                font-size: 1.4rem;
            }
        }
    }
    .nav-first {
        &:before {
            @include icon(p-double-arrow-left);
        }
    }
    .nav-last {
        &:before {
            @include icon(p-double-arrow-right);
        }
    }
    .nav-left {
        &:before {
            @include icon(p-arrow-left);
        }
    }
    .nav-right {
        &:before {
            @include icon(p-arrow-right);
        }
    }
}
@media screen and (max-width: 380px) {
    .pagination {
        a {
            width: max-content;
            min-width: 2.5rem;
            height: 2.5rem;
            padding-inline: .2rem;
        }
    }
}
@include mq(col-md) {
    .pagination {
        &-wrap {
            overflow: unset;
        }
        a {
            width: 3.4rem;
            height: 3.4rem;
        }
        &-number {
            .pagination-dots {
                margin-inline: var(--space-1);
            }
            a {
                margin-inline: var(--space-1);
                width: max-content;
                min-width: 3.4rem;
            }
        }
    }
}