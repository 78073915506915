@use "../config/" as *;
.waf-standings {
    --_more-color: var(--secondary-800);
    @include drop-arrow();
    @extend %py-8;
    @extend %px-4;
    @include mq(col-lg) {
        padding-bottom: 0;
        height: 100%;
    }
    .layout-wrapper {
        padding-bottom: 6rem;
        @extend %half-radius;
        @extend %relative;
        @extend %p-4 !optional;
        @extend %pure-white-900-bg;
    }
    .dropdown-wrap {
        position: absolute;
        top: var(--space-4);
        right: var(--space-5);
        width: 11rem;
    }
    .waf-select-box {
        border: .1rem solid clr(neutral-50);
        background-color: transparent;
        min-width: 11rem;
        @extend %p-2;
        @extend %half-radius;
        .select-box-wrap {
            background: var(--neutral-50);
        }
    }
    .head-tab {
        position: absolute;
        padding: 0;
        inset: auto var(--space-3) var(--space-3);
        li {
            border: 0;
        }
        a {
            justify-content: center;
            text-transform: uppercase;
            border: 0;
            @extend %primary-300-bg;
            @extend %py-3;
            @include mq(col-md) {
                background-color: transparent;
                border: 0.1rem solid clr(secondary-800);
                transition: background-color 300ms;
                &:hover {
                    background-color: var(--primary-300);
                }
            }
        }
    }
    .waf-head {
        padding-right: 10rem;
        @extend %flex-sb-c;
        @extend %my-3;
    }
    .selected-title {
        &::after {
            font-size: 1.2rem;
        }
        &[aria-expanded=true] {
            &::after {
                rotate: 180deg
            }
            & ~ .select-box-wrap {
                display: block;
            }
        }
    }
    .waf-footer,
    .dropdown-label {
        @extend %d-none;
    }
    .dropdown-wrap {
        @extend %ml-auto;
    }
    .position {
        width: 5rem;
    }
    .team {
        justify-content: flex-start;
        &-information {
            @extend %flex-n-c;
            @extend %gap-2;
        }
        &-image {
            width: 3rem;
            height: 1.8rem;
            @extend %neutral-50-bg;
        }
    }
    .form-guide {
        width: 16rem;
        flex-shrink: 0;
        display: none;
        @include mq(col-md) {
            display: flex;
        }
    }
    .form-guide {
        justify-content: flex-start;
        &-listing {
            list-style: none;
            @extend %p-0;
            @extend %flex;
            @extend %gap-2;
        }
        &-item {
            width: 2rem;
            height: 2rem;
            @extend %circle-radius;
            @extend %neutral-800-bg;
            @extend %neutral-50;
            @extend %flex-c-c;
            @extend %font-9;
            &.lost {
                @extend %error-900-bg;
            }
            &.win {
                @extend %success-900-bg;
            }
            &.draw {
                @extend %secondary-50-bg;
            }
        }
    }
    .table-body {
        .team {
            .text {
                font-weight: 500;
            }
        }
        .points {
            .text {
                font-weight: 700;
            }
        }
        .team .text {
            font-size: 1.4rem;
            @extend %uppercase;
        }
    }
    &.widget-layout-04 {
        .dropdown-wrap {
            width: 15rem;
        }
        .waf-select-box {
            min-width: 15rem;
            .select-list {
                .list-item {
                    text-transform: capitalize;
                }
            }
            .selected-title {
                @extend %text-left;
                .btn-text {
                    @include truncate-text(1, 14);
                }
            }
        }
    }
}
@include mq(col-md) {
    .waf-standings {
        &.widget-layout-04 {
            .table-body {
                .table-data {
                    --_table-data-height: 4.5rem;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-standings {
        &.widget-layout-04 {
            .table-responsive {
                overflow-y: auto;
                height: 26.4rem;
            }
        }
    }
}
@include mq(col-xxl) {
    .waf-standings {
        &.widget-layout-04 {
            .table-responsive {
                height: 30.2rem;
            }
        }
    }
}
