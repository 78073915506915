@use "../config/" as *;
.waf-standings {
    .waf-head .head-tab {
        @extend %d-none;
    }
    .table-title {
        @extend %uppercase;
    }
    .layout-wrapper {
        padding: 0;
        background-color: transparent;
    }
    .table {
        &-title {
            @extend %font-16-pb;
            @extend %mb-4;
        }
        &-wrap {
            @extend %pure-white-900-bg;
            @extend %p-4;
            @extend %half-radius;
            &:not(:last-child) {
                @extend %mb-6;
            }
        }
        &-data {
            &.points {
                .text {
                    font-weight: 700
                }
            }
        }
    }
    &.widget-layout-20 {
        padding-block: var(--space-4);
        .team-image {
            border: .1rem solid var(--neutral-50);
            border-radius: .2rem;
        }
    }
}
@include mq(col-md) {
    .waf-standings {
        padding: 0;
        .layout-wrapper {
            padding-inline: 0;
            padding-block: 0;
        }
        .waf-head {
            margin-block: 0 var(--space-6);
            .title {
                font-size: 2.4rem;
            }
        }
        &.widget-layout-20 {
            padding-block: var(--space-0) var(--space-4);
        }
    }
    .waf-component {
        &.waf-series-ad {
            margin-inline: 0;
            margin: 0;
        }
    }
    .sticky-container-standing {
        padding-top: var(--space-10);
    }
}
@include mq(col-lg) {
    .waf-component {
        &.waf-series-ad {
            margin: 0;
        }
    }
}