@use "../config/" as *;
:is(.waf-shortcode) {
    padding: 0;
    @extend %mb-4;
    @extend %half-radius;
    h1,
    h2,
    h3,
    h4,
    h5 {
        font-size: inherit;
        font-weight: 700;
    }
    p,
    .text,
    li {
        padding-bottom: 0;
        line-height: inherit;
        word-wrap: unset;
    }
    .waf-head {
        padding-bottom: 0;
        margin-top: 0;
        @extend %mb-4;
        .title {
            margin-block: 0;
            font-weight: 700;
        }
    }
    &:not(.waf-quote) {
        padding: var(--space-4);
        background: var(--neutral-50);
    }
}
.waf-detail {
    .waf-standings {
        p,
        .text {
            @extend %font-12-pm;
        }
        .table {
            @extend %my-0;
        }
    }
    .waf-statsdetail {
        .head-to-head {
            .card-head {
                padding: var(--space-4) var(--space-4) 0;
            }
        }
    }
}
.waf-statslisting {
    .waf-head {
        padding-inline: 0;
    }
    .card {
        &-list {
            padding-inline: 0;
            overflow-x: auto;
            @extend %flex;
        }
        &-content {
            border-bottom: 0;
            @extend %mb-0;
            @extend %pb-0;
        }
        &-item {
            width: auto;
            flex: 1;
            min-width: 85%;
            // flex-shrink: 0;
            // width: calc(90% - var(--space-4));
        }
        &-head {
            .title {
                @extend %mb-0;
            }
        }
    }
}
.waf-cricketscorecard {
    .social-share {
        .social-items {
            gap: 0;
            background: var(--secondary-900);
        }
        .share-icon {
            @extend %flex-c-c;
        }
        .social-icon {
            @extend %transparent-bg;
            &:before {
                @extend %pure-white-900;
            }
        }
        .social-share-wrap {
            position: absolute;
            display: none;
        }
        .close {
            @extend %flex-c-c;
        }
        &.active {
            .social-share-wrap {
                @extend %flex;
            }
        }
    }
    .text {
        @extend %pure-white-900;
    }
    .match-venue {
        .text {
            font-size: 1.2rem;
        }
    }
    .match-weather {
        .text {
            font-size: 1.2rem;
        }
    }
    .card-head-top {
        .match-info {
            @extend %text-left;
            .text {
                color: var(--neutral-800);
                font-size: 1.2rem;
                font-weight: 700;
            }
        }
    }
    .score-data {
        .tabs {
            @extend %pl-0;
        }
        .over-item {
            .text {
                color: var(--neutral-600);
            }
            &.four {
                .text {
                    @extend %pure-white-900;
                }
            }
            &.six {
                .text {
                    @extend %pure-white-900;
                }
            }
            &.wicket {
                .text {
                    @extend %pure-white-900;
                }
            }
        }
    }
    .form-guide {
        &-wrapper {
            .tooltip {
                @extend %px-2;
                .text {
                    font-size: 1rem;
                }
            }
        }
    }
    .team-divider {
        .text-vs {
            color: var(--pure-white-900);
            font-weight: 700;
        }
    }
}
.waf-quote {
    padding: var(--space-12) var(--space-4);
    .text,
    p {
        color: var(--neutral-900);
    }
}
.waf-showcase {
    @include listing-card(horizontal, 10rem);
    .waf-head {
        @extend %flex;
    }
    .article {
        &-list {
            @extend %gap-6;
            @extend %flex-column;
        }
        &-item {
            overflow: visible;
            @extend %relative;
            &:not(:last-child) {
                &:after {
                    content: '';
                    height: .1rem;
                    @extend %neutral-100-bg;
                    @extend %w-100;
                    @include position(null, null, var(--space-3-neg), 0);
                }
            }
        }
        &-title {
            font-weight: 700;
            line-height: 1.6rem;
            height: auto;
            @extend %mt-4;
            @extend %neutral-800;
            // @extend %my-6;
        }
        &-meta {
            @extend %mb-0;
            .meta-category {
                left: var(--space-4);
                @extend %pr-0;
            }
        }
        &-content {
            padding: 0 0 0 var(--space-4);
            position: relative;
            > a {
                @extend %neutral-800;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &-description {
            @extend %d-none;
            .text {
                font-size: 1.4rem;
                line-height: 2rem;
            }
        }
    }
}
.waf-statsdetail  {
    .waf-head {
        @extend %flex;
    }
    .head-to-head {
        .card-section {
            border: .1rem solid var(--neutral-200);
            background: var(--pure-white-900);
            @extend %p-0;
            @extend %full-radius;
        }
    }
    .table-responsive .btn-more {
        text-decoration: none;
    }
}
@include mq(col-md) {
    .waf-standings {
        padding-block: var(--space-4);
    }
    .waf-statslisting {
        padding: var(--space-4) 0;
        .card {
            &-list {
                overflow-x: auto;
                box-sizing: border-box;
            }
            &-item {
                width: calc(50% - var(--space-4));
                min-width: unset;
                &:last-child {
                    margin-right: var(--space-0);
                }
            }
        }
    }
    .waf-cricketscorecard {
        padding-inline: 0;
    }
    .waf-showcase {
        padding-inline: 0;
        .article-list {
            display: flex;
        }
    }
}
@include mq(col-lg) {
    :is(.waf-shortcode) {
        &:not(.waf-quote) {
            padding: var(--space-4);
        }
        .waf-head {
            margin-bottom: var(--space-5);
            .title {
                font-size: 1.6rem;
            }
        }
    }
    .waf-standings {
        .table-wrap {
            padding: 0;
            background: var(--neutral-50);
        }
        .table {
            overflow: hidden;
            background: var(--pure-white-900);
        }
    }
    .waf-statslisting {
        .card-list {
            &.card-list-more {
                .card-item {
                    width: 100%;
                    min-width: 35%;
                }
            }
        }
    }
    .waf-showcase {
        @include listing-card(vertical);
        .article {
            &-list {
                flex-direction: row;
                overflow-x: auto;
                gap: var(--space-4);
                &.more-article {
                    .article-item {
                        width: calc(33.33% - 1.1rem);
                        flex-shrink: 0;
                    }
                }
            }
            &-title {
                line-height: 2rem;
                margin-block: var(--space-8) var(--space-3);
            }
            &-item {
                flex: 1;
                &:not(:last-child) {
                    &::after {
                        content: unset;
                    }
                }
            }
            &-content {
                padding-left: 0;
            }
            &-meta {
                .meta-category {
                    left: 0;
                    top: var(--space-3);
                }
            }
        }
    }
    .waf-cricketscorecard {
        .card-head-top {
            .match-info {
                .text {
                    font-size: 1.4rem;
                }
            }
        }
    }
    .waf-detail {
        .waf-statsdetail {
            .table-head {
                background: transparent;
                .table-data {
                    background: var(--neutral-1000);
                }
                .table-row {
                    border-bottom: 0;
                }
            }
            .table-body .table-row:not(:last-child) {
                border: 0;
                .table-data {
                    border-bottom: .1rem solid hsl(var(--hsl-neutral-100)/0.2);
                }
            }
            .table {
                overflow-x: auto;
            }
            .waf-body {
                display: block;
            }
            .stats-wrapper {
                width: 100%;
            }
            .player-stats .card-name {
                margin-bottom: 0;
            }
            .head-to-head {
                .card-head {
                    padding: var(--space-6) var(--space-0);
                }
                .card-item {
                    padding-inline: var(--space-15);
                }
            }
        }
    }
}