@use "../config/" as *;
@forward './../partials/pagination';

.waf-detail {
    @extend %p-4;
    @extend %pure-white-900-bg;
    .article-head {
        @extend %relative;
        .title {
            background: linear-gradient(92.5deg, var(--secondary-800) -0.01%, var(--secondary-1000) 133.57%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            line-height: 1.2;
            @extend %py-6;
            // @extend %secondary-800;
            @extend %font-32-pb;
        }
        .article-description {
            .text,
            p {
                @extend %neutral-800;
                @extend %font-16-pr;
            }
            .drop-cap-intro,
            .intro {
                @extend %font-16-pb;
                @extend %mt-6;
                &:first-letter {
                    float: left;
                    font-size: 4.8rem;
                    line-height: 1;
                }
            }
        }
        .head-wrap {
            @extend %mb-4;
            @extend %flex-column;
        }
        .article-meta {
            @extend %mb-4;
            @extend %flex-n-c;
            .meta {
                @extend %neutral-500;
                @extend %font-10-pm;
                &:not(:last-child, :nth-last-child(2)) {
                    &::after {
                        content: "|";
                        @extend %mx-2;
                    }
                }
                &.meta-read-time {
                    &::after {
                        display: none;
                    }
                }
                &.meta-category {
                    font-size: 1.2rem;
                    @extend %uppercase;
                    @extend %pos-tl;
                    @extend %secondary-600;
                }
                &.meta-author {
                    margin-bottom: 0;
                    &:empty {
                        display: none;
                    }
                }
            }
        }
        a {
            text-decoration: none;
        }
    }
    .drop-cap-intro,
    .intro {
        @extend %font-16-pb;
        &:first-letter {
            float: left;
            font-size: 4.8rem;
            line-height: 1;
        }
    }
    .img-box {
        @extend %half-radius;
    }
    .text,
    p,
    li {
        line-height: 1.3;
        word-wrap: break-word;
        @extend %neutral-800;
        @extend %pb-7;
        // @extend %mb-4;
        @extend %font-16-pr;
        &:empty {
            display: none;
        }
        strong {
            font-weight: 700;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 0.5em;
    }
    .article-featured-media {
        @extend %mb-3;
    }

    strong,
    .highlight {
        // @extend %primary-700;
        @extend %font-16-pb;
    }
    a {
        text-decoration: none;
        cursor: pointer;
        @extend %primary-700;
        @extend %font-16-pb;
        &:hover {
            text-decoration: underline;
            color: var(--primary-700);
        }
    }
    video {
        @extend %w-100;
    }
    // iframe {
    //     @extend %w-100;
    // }
    .detail-title {
        @extend %neutral-800;
        @extend %my-0;
        @extend %pb-4;
        @extend %font-18-pb;
    }
    ol,
    ul {
        @extend %pl-4;
        li {
            margin-bottom: 0;
        }
    }
    .tagline,
    .views-section,
    .article-commentbox,
    .article-tags {
        @extend %d-none;
    }
    .table {
        overflow: unset;
        @extend %my-2;
        tr {
            p {
                padding-bottom: 0;
            }
        }
    }
    .social-share {
        .social-share-wrap {
            position: unset;
            @extend %flex;
        }
        .social-items {
            @extend %gap-3;
            @extend %transparent-bg;
        }
        .social-icon {
            @extend %circle-radius;
            @extend %primary-200-bg;
            &::before {
                font-size: 1.6rem;
                @extend %neutral-800;
            }
        }
        .share-icon,
        .close {
            @extend %d-none;
        }
    }
    h2,
    h3,
    h4 {
        line-height: 1.2;
    }
    h3,
    h4 {
        font-weight: 400;
    }
    h2 {
        font-size: 2.4rem;
        font-weight: 500;
    }
    h3 {
        font-size: 2.2rem;
    }
    h4 {
        font-size: 1.8rem;
    }
    &.waf-video-page {
        .head-wrap {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        .article-meta {
            margin-bottom: 0;
        }
        .social-share {
            width: auto;
        }
    }
    .align-center,
    .align-left,
    .align-right {
        &.img-box {
            @extend %mb-4;
        }
    }
    .img-box {
        @extend %neutral-50-bg-5;
        figcaption {
            font-weight: 500;
        }
        figcaption,
        em {
            @extend %neutral-800;
            @extend %px-2;
            @extend %py-1;
            &:empty {
                @extend %d-none;
            }
        }
        em {
            font-style: normal;
            @extend %relative;
            &:before {
                content: '\00a9';
                @extend %mr-1;
            }
        }
    }
    .article-body {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            > *,
            > * > * {
                font-size: inherit;
                font-weight: inherit;
            }
        }
        .twitter-tweet,
        .instagram-media {
            margin-inline: auto !important;
        }
    }
    .detail-section .detail-title {
        line-height: 2rem;
        @extend %font-14-pb;
    }
    iframe:not(.waf-ad iframe) {
        width: 100%;
    }
}
@include mq(col-md) {
    .waf-detail {
        padding: var(--space-6);
        &.waf-video-page {
            margin-top: var(--space-4);
        }
        .article-head {
            .article-meta {
                margin-bottom: 0;
                .meta {
                    font-size: 1.2rem;
                    &.meta-category {
                        font-size: 1.4rem
                    }
                }
            }
            .head-wrap {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }
        .social-share {
            width: auto;
        }
        .detail-section .detail-title {
            // max-width: 55rem;
            // margin-inline: auto;
            font-size: 1.6rem;
            line-height: 2.2rem;
        }
    }
}
@include mq(col-lg) {
    .waf-detail {
        &.waf-video-page {
            margin-top: 0;
        }
        .article-head {
            .title {
                font-size: 4.2rem;
            }
        }
        h2 {
            font-size: 3.2rem;
        }
        h3 {
            font-size: 2.8rem;
        }
        h4 {
            font-size: 2.4rem;
        }
    }
    .waf-listing {
        &.related-list {
            margin-top: 0;
        }
    }
    .sticky-container-detail {
        padding-block: var(--space-5);
    }
    .site-content {
        &.detail-ads-sticky {
            position: sticky;
            top: var(--header-height);
        }
    }
}