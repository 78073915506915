@use "../config/" as *;
.waf-quote {
    @extend %primary-300-bg;
    .quote-wrapper {
        @extend %text-center;
    }
    .title {
        isolation: isolate;
        @extend %relative;
        @extend %neutral-800;
        @extend %font-24-pb;
        @extend %mb-7;
        &::before,
        &::after {
            content: '';
            width: 7rem;
            height: 6rem;
            z-index: var(--z-quotes);
            @include background(null, "icons/quote.png", center / contain no-repeat);
        }
        &::before {
            @include position(var(--space-8-neg), null, null, 0);
        }
        &::after {
            rotate: 360deg;
            transform: scaleX(-1);
            @extend %ml-2;
            @include position(null, null, var(--space-12-neg), null);
        }
    }
    .text {
        @extend %font-14-pm;
        @extend %neutral-800;
    }
}
@include mq(col-md) {
    .waf-quote {
        padding: var(--space-7) var(--space-12);
        .text {
            font-size: 1.8rem;
            line-height: 2rem;
        }
        .title {
            display: inline-block;
            &::before,
            &::after {
                width: 3.2rem;
                height: 2.5rem;
            }
            &::before {
                top: var(--space-2-neg);
                left: var(--space-10-neg);
            }
            &::after {
                bottom: 0;
            }
        }
    }
}